

import { IoIosArrowForward } from "react-icons/io";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { HiOutlineX } from "react-icons/hi";


import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { setLanguage, updateLanguage } from '@/store/slices/settingsSlice';

interface LanguageMenuProps {
    hideHandler: () => void;
    goToMainMenu: () => void;
}

export const LanguageMenu = ({
    hideHandler,    
    goToMainMenu
}:LanguageMenuProps) => {
    const dispatch = useAppDispatch();

    type LanguageChoices= 'en' | 'jp'

    const userData = useAppSelector(state => state.user).data
    // const {token, is_organizer, email} = userData

    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    // Handle language change and sync with router
    const handleLanguageChange = (newLanguage:LanguageChoices) => {
        if (newLanguage !== l) {
            //check if user logged or not
            let loggedIn = false
            if (userData.token) {
                loggedIn = true
            }
            // logged in: set to user settings in backend data
            if (loggedIn) {
                dispatch(updateLanguage({language: newLanguage}))
            // not logged in: only set state in redux
            } else {
                dispatch(setLanguage(newLanguage))
            }
            hideHandler()
        }
    };


    return (
        <div className='w-full h-full flex flex-col gap-2'>
            <div className={`
                w-full flex gap-4 items-center px-3 py-3 border-b
                flex-row justify-start items-center
            `}>
                <HiOutlineArrowNarrowLeft 
                    size={18} tabIndex={-1}
                    onClick={goToMainMenu}
                    className='cursor-pointer'
                />
                <p className='font-bold'>Language</p>
                <HiOutlineX 
                    onClick={hideHandler}
                    size={18} tabIndex={-1}
                    className='cursor-pointer ml-auto'
                />
            </div>            

            <div 
                onClick={() => handleLanguageChange('jp')}
                className='px-6 py-3 cursor-pointer hover:bg-gray-100'
            >
                <p className={`text-sm sm:text-base
                    ${l === 'jp' ? 'font-bold' : ''}
                `}>
                    日本語
                </p>
            </div>
            <div 
                onClick={() => handleLanguageChange('en')}
                className='px-6 py-3 cursor-pointer hover:bg-gray-100'
            >
                <p className={`text-sm sm:text-base
                    ${l === 'en' ? 'font-bold' : ''}
                `}>
                    English
                </p>
            </div>

        </div>
    )
}