
import { BouncingLoader } from "@/src/atoms-v3/loader/BouncingLoader";

interface SimpleButtonProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children: React.ReactNode;
    className?: string;
    color?: 'orange' | 'blue' | 'red' | 'black' | 'none';
    bg?: 'solid' | 'outline'
    rounded?: string;
    isSubmit?: boolean;
    isTabbable?: boolean;
    loading?: boolean;
    disabled?: boolean;
}

export const SimpleButton = ({
    onClick,
    children,
    className='',
    color='black',
    bg='outline',
    rounded='rounded-md',
    isSubmit=false,
    isTabbable=true,
    loading=false,
    disabled=false,
    
}:SimpleButtonProps) => {

    /** Click handling */
    const clickHandler = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // setIsActive(!isActive)
        if (onClick) {
          onClick(e);
        }
    }

    return (
        <button
            type={isSubmit ? 'submit' : 'button'}
            onClick={clickHandler}
            tabIndex={isTabbable ? 0 : -1}
            disabled={disabled || loading}
            className={`
                disabled:border-transparent
                disabled:text-gray-500
                 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:select-none
                ${ (bg === 'solid') ? (
                    color=='orange' ? 'bg-main-orange text-white' : 
                    color=='blue' ? 'bg-blue-700 text-white' :
                    color=='red' ? 'bg-red-700 text-white' :
                    color=='black' ? 'bg-gray-900 text-white' : ''
                ) : (bg === 'outline') ? (
                    color=='orange' ? 'bg-white text-main-orange border border-main-orange' : 
                    color=='blue' ? 'bg-white text-blue-700 border border-blue-700' :
                    color=='red' ? 'bg-white text-red-700 border border-red-700' :
                    color=='black' ? 'bg-white text-gray-900 border border-gray-900' : ''
                ) : ('')
                }
                p-2 w-fit
                ${rounded}
                ${className}
            `}
        >
            {   loading ? (
                <BouncingLoader className='mt-3'/>
            ) : (
                children
            )}
        </button>
    )
}