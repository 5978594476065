import React, {useRef, useEffect, ReactNode, useState} from 'react'

import { createPortal } from 'react-dom';

import { HiOutlineX } from "react-icons/hi";


//hooks for modal
import useOutsideClick from '@/src/hooks/useOutsideClick2'


interface ModalContainerProps {
    isStatic?: boolean;
    hideCloseButton?: boolean;

    isVisible: boolean;
    hideModalCallback: () => void;
    children?: JSX.Element | ReactNode;
    
    parentClassName?: string;
    containerClassName?: string;
    containerAlignment?: string;
    containerRounded?: string;
    overlayDelay?: number;
    hideOverlay?: boolean;
}

export const ModalContainer = ({
    isStatic=false,
    hideCloseButton=false,

    isVisible,
    hideModalCallback,
    children,
    parentClassName='',
    containerClassName='',
    containerAlignment='items-end sm:items-center',
    containerRounded='rounded-t-lg sm:rounded-lg',
    overlayDelay = 100,
    hideOverlay = false
}: ModalContainerProps) => {

    const ref = useRef(null);
    useOutsideClick(ref, () => { if (!hideOverlay) {hideModalCallback(); } });

    const [isContainerVisible, setIsContainerVisible] = useState(false);


    //disable scroll on page
    useEffect(() => {
        // Disable scrolling when modal is open
        if (isVisible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    
        // Cleanup on unmount
        return () => {
            document.body.style.overflow = "";
        };
    }, [isVisible]);

    useEffect(() => {
        if (isVisible && !hideOverlay) {
            setIsContainerVisible(true);
            document.body.classList.add('overflow-hidden');
        } else {
            setTimeout(() => {
                setIsContainerVisible(false);
                document.body.classList.remove('overflow-hidden');
            }, overlayDelay);
        }
        return () => {
            // Clean up when the component unmounts
            document.body.classList.remove('overflow-hidden');
        };
    }, [isVisible, overlayDelay]);



    return (
        createPortal(
            <>
                { !hideOverlay ? (
                    <div // Overlay div
                        tabIndex = {-1}         //for modal screen
                        aria-hidden="true"    //remove children from accessing tree
                        className={`fixed flex items-center justify-center bg-black bg-opacity-50 z-[100] w-full h-full overflow-hidden inset-0
                            transition-opacity duration-300 ease-in-out
                            pointer-events-none
                            ${isVisible ? 'opacity-100' : 'opacity-0'} 
                            ${hideOverlay ? 'hidden' : ''}
                        `}
                    ></div>

                ) : (<> </>)}
                
                <div  // Main Modal -- Overlay div
                    // ref = {wrapperRef}
                    
                    className = {`
                        fixed flex w-full h-full overflow-x-hidden overflow-y-hidden inset-0
                        transition-all duration-300 ease-in-out
                        ${containerAlignment}
                        ${isVisible ? 
                            'translate-y-0 opacity-100'
                            : 'translate-y-full opacity-0'}
                        ${ !hideOverlay ? 'z-[101]' : ''}
                `}> 
                    {/**   Wrapper Div for the Modal Screen */}
                    <div
                        ref = {isStatic ? null : ref}
                        className={`
                            relative
                            max-h-full max-w-full
                            min-h-[10rem]
                            ml-auto mr-auto bg-white
                            ${containerRounded}
                            ${containerClassName}
                        `}
                    >
                        {   !hideCloseButton ? (
                            <button 
                                type="button" 
                                className={`
                                    absolute top-1 right-1
                                    text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 ml-auto inline-flex items-center
                                `}
                                onClick={hideModalCallback}
                            >
                                <HiOutlineX size={20}/>
                                {/* <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg> */}
                                <span className="sr-only">Close modal</span>
                            </button>
                        ) : (<></>)
                        }
                        
                        <div className={`${parentClassName} overflow-y-scroll overflow-x-hidden`}>
                            {children}
                        </div>
                    </div>
                </div>
            </>, document.body
        )
    )
}