import {useState, useEffect} from 'react'
import { useRouter } from 'next/router';

import { HiOutlineMenu } from "react-icons/hi";
import { HeaderLogo } from '@/src/atoms-v3/headerLogo';


import { LoginSignupModal } from '@/src/components-v3/modals-drawers/login-signup-modal/LoginSignupModal';
import { UserDrawerMenu } from '@/src/components-v3/menus/user-drawer-menu/UserDrawerMenu';

export async function getServerSideProps() {
    const backend_url = process.env.BACKEND_URL;
    return { props: { backend_url }, };
}

interface LoginProps {
}

export default function Login({
}: LoginProps) {
    const router = useRouter();
    
    /** LOGIN SIGNUP MODAL */
    const [loginSignupVisible, setLoginSignupVisible] = useState(false)


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoginSignupVisible(true);
        }, 300); // 1 second delay

        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, []);

    /** DRAWER MENU VISIBLE STATE */
    const [drawerMenuVisible, setDrawerMenuVisible] = useState(false)


    return (
        <div className='w-screen h-screen bg-gray-800 flex'>
            <div className='w-full h-fit top-0 sticky z-[20] flex flex-row gap-3 items-center p-3'> 
                <div>
                    <HiOutlineMenu 
                        size={24} className='cursor-pointer text-white'
                        onClick={() => setDrawerMenuVisible(true)}
                    />
                </div>
                <div onClick={() => router.push('/')}>
                    <HeaderLogo />
                </div>
            </div>
            
            <LoginSignupModal 
                isVisible={loginSignupVisible}
                hideModalCallback={() => console.log('cannot close')}
                hideOverlay={true}
            />
            <UserDrawerMenu 
                isVisible={drawerMenuVisible}
                hideDrawerCallback={() => setDrawerMenuVisible(false)}
                is_left={true}
            />
        </div>
    )
}
